import {
  Box,
  Flex,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Tooltip,
  TooltipProps,
} from "@chakra-ui/react"
import React, { FC, PropsWithoutRef } from "react"
import { useField } from "react-final-form"

import { useLocaleContext } from "../../utils/misc/LocaleContext"
import { useFormValidationContext } from "./Form"

export interface FieldProps {
  name: string
  label?: string | JSX.Element
  labelSibling?: React.ReactNode
  outerProps?: PropsWithoutRef<FormControlProps>
  helperText?: React.ReactNode
  isRequired?: boolean
  isReadOnly?: boolean
  isDisabled?: boolean
  errorAsTooltip?: boolean
}

const Field: FC<FieldProps> = ({
  name,
  label,
  labelSibling,
  helperText,
  outerProps,
  isRequired,
  isReadOnly,
  isDisabled,
  errorAsTooltip,
  children,
}) => {
  const { alwaysShowValidationError } = useFormValidationContext()

  const { isRTL } = useLocaleContext()
  const {
    meta: { visited, error, submitError, submitting, ...metaRest },
    ...rest
  } = useField(name)

  const normalizedError = Array.isArray(error) ? error.join(", ") : error || submitError

  const InputWrapper = errorAsTooltip ? Tooltip : React.Fragment
  const inputWrapperProps: Omit<TooltipProps, "children"> = errorAsTooltip
    ? {
        label: normalizedError,
        hasArrow: true,
        placement: isRTL ? "left" : "right",
        isOpen: true,
      }
    : {}

  return (
    <FormControl
      mt={6}
      {...outerProps}
      id={name}
      isRequired={isRequired}
      isInvalid={(visited || alwaysShowValidationError) && !!normalizedError}
      isDisabled={isDisabled || submitting}
      isReadOnly={isReadOnly}
    >
      {(label || labelSibling) && (
        <Flex alignItems="center" justifyContent="between" mb={2}>
          {label && <FormLabel mb={0}>{label}</FormLabel>}
          {labelSibling && <Box ms="auto">{labelSibling}</Box>}
        </Flex>
      )}

      <InputWrapper {...inputWrapperProps}>{children}</InputWrapper>

      {helperText && <FormHelperText>{helperText}</FormHelperText>}

      {!errorAsTooltip && <FormErrorMessage>{normalizedError}</FormErrorMessage>}
    </FormControl>
  )
}

export default Field
