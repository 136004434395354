// inspired by https://github.com/blitz-js/blitz/blob/canary/packages/core/src/errors.ts
import { SubjectWizardStep } from "db"
import SuperJson from "superjson"
import { ZodIssue } from "zod"

const errorProps = ["name", "message", "code", "statusCode", "meta"]

if (process.env.JEST_WORKER_ID === undefined) {
  SuperJson.allowErrorProps(...errorProps)
}

export class BadGatewayError extends Error {
  name = "BadGatewayError"
  statusCode = 502
  constructor(message = "Bad Gateway 502") {
    super(message)
  }
  get _clearStack() {
    return true
  }
}
if (process.env.JEST_WORKER_ID === undefined) {
  SuperJson.registerClass(BadGatewayError, {
    identifier: "BadGatewayError",
    allowProps: errorProps,
  })
}

export class BadRequestError extends Error {
  name = "BadRequestError"
  statusCode = 400
  constructor(message = "Bad Request 400") {
    super(message)
  }
  get _clearStack() {
    return true
  }
}
if (process.env.JEST_WORKER_ID === undefined) {
  SuperJson.registerClass(BadRequestError, {
    identifier: "BadRequestError",
    allowProps: errorProps,
  })
}

export class ProductTypeTakenError extends Error {
  name = "ProductTypeTakenError"
  statusCode = 400
  constructor(message = "Product type is activated already") {
    super(message)
  }
  get _clearStack() {
    return true
  }
}
if (process.env.JEST_WORKER_ID === undefined) {
  SuperJson.registerClass(ProductTypeTakenError, {
    identifier: "ProductTypeTakenError",
    allowProps: errorProps,
  })
}

export class PaymentIdTakenError extends Error {
  name = "PaymentIdTakenError"
  statusCode = 400
  constructor(message = "Activation code has been used already") {
    super(message)
  }
  get _clearStack() {
    return true
  }
}
if (process.env.JEST_WORKER_ID === undefined) {
  SuperJson.registerClass(PaymentIdTakenError, {
    identifier: "PaymentIdTakenError",
    allowProps: errorProps,
  })
}

export type IssueMap = { [key in SubjectWizardStep]?: ZodIssue[] }

export class SubmissionError extends Error {
  name = "SubmissionError"
  statusCode = 400
  issueMap: IssueMap

  constructor(message = "Validation error", issueMap: IssueMap) {
    super(message)
    this.issueMap = issueMap
  }
  get _clearStack() {
    return true
  }
}
if (process.env.JEST_WORKER_ID === undefined) {
  SuperJson.registerClass(SubmissionError, {
    identifier: "SubmissionError",
    allowProps: [...errorProps, "issueMap"],
  })
}

export class ShareEmailTakenError extends Error {
  name = "ShareEmailTakenError"
  statusCode = 400
  constructor(message = "Subject is already shared with this email") {
    super(message)
  }
  get _clearStack() {
    return true
  }
}
if (process.env.JEST_WORKER_ID === undefined) {
  SuperJson.registerClass(ShareEmailTakenError, {
    identifier: "ShareEmailTakenError",
    allowProps: errorProps,
  })
}

export class DeviceIdsTakenError extends Error {
  name = "SampleIdsTakenError"
  statusCode = 400
  constructor(deviceIds: string[]) {
    super(deviceIds.join(", "))
  }
  get _clearStack() {
    return true
  }
}
if (process.env.JEST_WORKER_ID === undefined) {
  SuperJson.registerClass(DeviceIdsTakenError, {
    identifier: "SampleIdsTakenError",
    allowProps: errorProps,
  })
}

export class DeviceIdsNotFoundError extends Error {
  name = "DeviceIdsNotFoundError"
  statusCode = 400
  constructor(deviceIds: string[]) {
    super(deviceIds.join(", "))
  }
  get _clearStack() {
    return true
  }
}
if (process.env.JEST_WORKER_ID === undefined) {
  SuperJson.registerClass(DeviceIdsNotFoundError, {
    identifier: "DeviceIdsNotFoundError",
    allowProps: errorProps,
  })
}

export class DeviceIdInvalidError extends Error {
  name = "DeviceIdInvalidError"
  statusCode = 400
  constructor(public subjectId: string | null, message = "This sample has already been used") {
    super(message)
  }
  get _clearStack() {
    return true
  }
}
if (process.env.JEST_WORKER_ID === undefined) {
  SuperJson.registerClass(DeviceIdInvalidError, {
    identifier: "DeviceIdInvalidError",
    allowProps: [...errorProps, "subjectId"],
  })
}

export class DeviceIdMissingError extends Error {
  name = "DeviceIdMissingError"
  statusCode = 400
  constructor(public subjectId: string, message = "You need to provide another sample") {
    super(message)
  }
  get _clearStack() {
    return true
  }
}
if (process.env.JEST_WORKER_ID === undefined) {
  SuperJson.registerClass(DeviceIdMissingError, {
    identifier: "DeviceIdMissingError",
    allowProps: [...errorProps, "subjectId"],
  })
}

export class DeviceIdDuplicateError extends Error {
  name = "DeviceIdDuplicateError"
  statusCode = 400
  constructor(public subjectId: string | null, message = "This sample ID was sent multiple times") {
    super(message)
  }
  get _clearStack() {
    return true
  }
}
if (process.env.JEST_WORKER_ID === undefined) {
  SuperJson.registerClass(DeviceIdDuplicateError, {
    identifier: "DeviceIdDuplicateError",
    allowProps: [...errorProps, "subjectId"],
  })
}

export class FileValidationError extends Error {
  name = "FileValidationError"
  statusCode = 400
  constructor(message = "File validation failed") {
    super(message)
  }
  get _clearStack() {
    return true
  }
}
if (process.env.JEST_WORKER_ID === undefined) {
  SuperJson.registerClass(FileValidationError, {
    identifier: "FileValidationError",
    allowProps: errorProps,
  })
}

export class EmailOrPhoneMissingError extends Error {
  name = "EmailOrPhoneMissingError"
  statusCode = 400
  constructor(message = "Must provide phone number or email") {
    super(message)
  }
  get _clearStack() {
    return true
  }
}
if (process.env.JEST_WORKER_ID === undefined) {
  SuperJson.registerClass(EmailOrPhoneMissingError, {
    identifier: "EmailOrPhoneMissingError",
    allowProps: errorProps,
  })
}

export class ReportFilenameTakenError extends Error {
  name = "ReportFilenameTakenError"
  statusCode = 400
  constructor() {
    super(ReportFilenameTakenError.name)
  }
  get _clearStack() {
    return true
  }
}
if (process.env.JEST_WORKER_ID === undefined) {
  SuperJson.registerClass(ReportFilenameTakenError, {
    identifier: "ReportFilenameTakenError",
    allowProps: errorProps,
  })
}

export class EmailVerificationError extends Error {
  name = "EmailVerificationError"
  statusCode = 400
  constructor(message = "Email is not verified") {
    super(message)
  }
  get _clearStack() {
    return true
  }
}
if (process.env.JEST_WORKER_ID === undefined) {
  SuperJson.registerClass(EmailVerificationError, {
    identifier: "EmailVerificationError",
    allowProps: errorProps,
  })
}

export class TwoFactorInitiationError extends Error {
  name = "TwoFactorInitiationError"
  statusCode = 400
  constructor(message = "Two factor ceremony begins") {
    super(message)
  }
  get _clearStack() {
    return true
  }
}
if (process.env.JEST_WORKER_ID === undefined) {
  SuperJson.registerClass(TwoFactorInitiationError, {
    identifier: "TwoFactorInitiationError",
    allowProps: errorProps,
  })
}

export class WrongTwoFactorTokenError extends Error {
  name = "WrongTwoFactorTokenError"
  statusCode = 400
  constructor(message = "Wrong two factor token") {
    super(message)
  }
  get _clearStack() {
    return true
  }
}
if (process.env.JEST_WORKER_ID === undefined) {
  SuperJson.registerClass(WrongTwoFactorTokenError, {
    identifier: "WrongTwoFactorTokenError",
    allowProps: errorProps,
  })
}

export class LockoutError extends Error {
  name = "LockoutError"
  statusCode = 400
  constructor(message = "Too many failed login attempts") {
    super(message)
  }
  get _clearStack() {
    return true
  }
}
if (process.env.JEST_WORKER_ID === undefined) {
  SuperJson.registerClass(LockoutError, {
    identifier: "LockoutError",
    allowProps: errorProps,
  })
}

export class UserRestricted extends Error {
  name = "UserRestricted"
  statusCode = 400
  constructor(message = "User is restricted") {
    super(message)
  }
  get _clearStack() {
    return true
  }
}
if (process.env.JEST_WORKER_ID === undefined) {
  SuperJson.registerClass(UserRestricted, {
    identifier: "UserRestricted",
    allowProps: errorProps,
  })
}
