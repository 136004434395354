import { Alert, AlertIcon } from "@chakra-ui/alert"
import { Button, ButtonProps } from "@chakra-ui/button"
import { Flex } from "@chakra-ui/layout"
import React, { PropsWithoutRef, ReactNode } from "react"
import { useContext } from "react"
import { Form as FinalForm, FormProps as FinalFormProps } from "react-final-form"
import { useTranslation } from "react-i18next"
import * as z from "zod"

import { useZodErrorMap } from "../../utils/errorHandling/zodErrorMap"
export { FORM_ERROR } from "final-form"

const FormValidationContext = React.createContext({ alwaysShowValidationError: false })

/**
 * Used to figure out whether or not we should always show validation errors in field components.
 */
export const useFormValidationContext = () => useContext(FormValidationContext)

export interface FormProps<S extends z.ZodType<any, any>>
  extends FinalFormProps,
    Omit<PropsWithoutRef<JSX.IntrinsicElements["form"]>, "onSubmit"> {
  /** All your form fields */
  children?: ReactNode
  /** Text to display in the submit button */
  submitText?: string
  backText?: string
  schema?: S
  onSubmit: FinalFormProps<z.infer<S>>["onSubmit"]
  onBack?: () => void
  initialValues?: FinalFormProps<z.infer<S>>["initialValues"]
  buttonProps?: ButtonProps
  alwaysShowValidationError?: boolean
}

export function Form<S extends z.ZodType<any, any>>({
  children,
  submitText,
  backText,
  schema,
  initialValues,
  onSubmit,
  onBack,
  buttonProps,
  alwaysShowValidationError = false,
  ...restProps
}: FormProps<S>) {
  const { t } = useTranslation()
  const zodErrorMap = useZodErrorMap()
  z.setErrorMap(zodErrorMap)
  return (
    <FormValidationContext.Provider value={{ alwaysShowValidationError }}>
      <FinalForm
        noValidate={true}
        initialValues={initialValues}
        validate={(values) => {
          if (!schema) return
          try {
            schema.parse(values, { errorMap: zodErrorMap })
          } catch (error) {
            console.log(error)
            return error.formErrors.fieldErrors
          }
        }}
        onSubmit={onSubmit}
        render={(renderProps) => {
          const { handleSubmit, submitting, submitError } = renderProps
          return (
            <Flex as="form" direction="column" onSubmit={handleSubmit}>
              {/* Form fields supplied as children are rendered here */}
              {typeof children === "function" ? children(renderProps) : children}

              {submitError && (
                <Alert status="error" variant="solid" mt={8}>
                  <AlertIcon />
                  {submitError}
                </Alert>
              )}

              {(onBack || submitText) && (
                <Flex alignItems="center" mt={10}>
                  {onBack && (
                    <Button
                      type="button"
                      me="auto"
                      colorScheme="expertBlue"
                      variant="outline"
                      onClick={onBack}
                    >
                      {backText || t("actions.back")}
                    </Button>
                  )}

                  {submitText && (
                    <Button
                      ms="auto"
                      me={onBack ? 0 : "auto"}
                      type="submit"
                      colorScheme="expertBlue"
                      isLoading={submitting}
                      {...buttonProps}
                    >
                      {submitText}
                    </Button>
                  )}
                </Flex>
              )}
            </Flex>
          )
        }}
        {...restProps}
        mutators={{
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value)
          },
          ...(restProps.mutators ?? {}),
        }}
      />
    </FormValidationContext.Provider>
  )
}

export default Form
