import { useMemo } from "react"
import { TFunction, useTranslation } from "react-i18next"
import * as z from "zod"

const getZodErrorMap = (t: TFunction): z.ZodErrorMap => {
  const zodErrorMap: z.ZodErrorMap = (issue, ctx) => {
    if (issue.code === z.ZodIssueCode.invalid_type) {
      if (
        issue.received === "undefined" ||
        issue.received === undefined ||
        issue.received === "null"
      ) {
        return { message: t("errors.invalid_type_required") }
      }
      if (issue.expected === "integer") {
        return { message: t("errors.invalid_type_integer") }
      }
    }

    if (issue.code === z.ZodIssueCode.invalid_string) {
      if (issue.validation === "email") {
        return { message: t("errors.invalid_string_email") }
      }
    }

    if (issue.code === z.ZodIssueCode.invalid_enum_value) {
      return {
        message: t("errors.invalid_enum_value", { options: issue.options.join(", ") }),
      }
    }

    if (issue.code === z.ZodIssueCode.too_small) {
      if (issue.type === "string" && issue.inclusive && issue.minimum === 1) {
        return {
          message: t("errors.string_empty"),
        }
      }

      return {
        message: t("errors.too_small", {
          minimum: issue.inclusive ? issue.minimum : issue.minimum + 1,
        }),
      }
    }

    if (issue.code === z.ZodIssueCode.too_big) {
      return {
        message: t("errors.too_big", {
          maximum: issue.inclusive ? issue.maximum : issue.maximum - 1,
        }),
      }
    }
    // created by booleanString used by radio inputs
    if (issue.code === z.ZodIssueCode.invalid_union) {
      if (
        issue.unionErrors
          .map((error) => error.issues)
          .flat()
          .some((issue) => issue.code === "invalid_type" && issue.received === undefined)
      ) {
        return { message: t("errors.invalid_union_required") }
      }
    }

    return { message: ctx.defaultError }
  }
  return zodErrorMap
}

export function useZodErrorMap(): z.ZodErrorMap {
  const { t } = useTranslation()

  return useMemo(() => getZodErrorMap(t), [t])
}
